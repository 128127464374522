<template>
    <div class="temoinage">
        <h2>Ils nous font confiance : </h2>

        <div class="experience_container">
            <h3>Experiences :</h3>
            <div class="experience" v-scroll-reveal="{ duration: 800, delay: 50, origin: 'right', distance: '50px'}">
                <div class="profil">
                    <div class="pdp">
                        <div class="img"></div>
                    </div>
                    <p>SNCF</p>
                </div>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic esse tempora quidem sunt quibusdam molestiae commodi quam?</p>
            </div>
            <div class="experience" v-scroll-reveal="{ duration: 800, delay: 150, origin: 'right', distance: '50px'}">
                <div class="profil">
                    <div class="pdp">
                        <div class="img"></div>
                    </div>
                    <p>Nexity</p>
                </div>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic esse tempora quidem sunt quibusdam molestiae commodi quam?</p>
            </div>
            <div class="experience" v-scroll-reveal="{ duration: 800, delay: 250, origin: 'right', distance: '50px'}">
                <div class="profil">
                    <div class="pdp">
                        <div class="img"></div>
                    </div>
                    <p>RATP</p>
                </div>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic esse tempora quidem sunt quibusdam molestiae commodi quam?</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss">
    .temoinage {
        h2 {
            text-transform: uppercase;
            margin-top: 30px;
            color: #3c97f7;
            text-align: center;
        }
        h3 {
            margin-top: 150px;
            margin-bottom: 30px;
            text-align: center;
        }

        .experience_container {
            width: 90%;
            margin: 0 auto 100px auto;
        }
        .experience {
            margin: 30px auto;
            max-width: 700px;
            display: flex;
            align-items: center;
            padding: 20px;
            border: 2px solid #3c97f7;
            border-radius: 15px;

            .profil {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;

                p {
                    margin: 0;
                    font-weight: 500;
                    margin-top: 8px;
                }
            }
            .pdp {
                width: 70px;
                height: 70px;
                position: relative;
                border-radius: 50%;
                overflow: hidden;

                .img {
                    width: 100%;
                    height: 100%;
                    background-color: #3c97f7;
                }
            }
            p {
                width: 85%;
                margin-left: 30px;
            }
        }
    }
</style>